import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				AceTennis – Kortunuzu Bugün Rezerve Edin
			</title>
			<meta name={"description"} content={"AceTennis'te en kaliteli tenis kortu kiralama hizmetlerini keşfedin. Kortlarımız maçlar, antrenmanlar ve dostluk oyunları için mükemmeldir. Şimdi rezervasyon yaparak üstün bir tenis deneyimi yaşayın!"} />
			<meta property={"og:description"} content={"AceTennis'te en kaliteli tenis kortu kiralama hizmetlerini keşfedin. Kortlarımız maçlar, antrenmanlar ve dostluk oyunları için mükemmeldir. Şimdi rezervasyon yaparak üstün bir tenis deneyimi yaşayın!"} />
			<link rel={"shortcut icon"} href={"https://glowesbair.org/img/5738682.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowesbair.org/img/5738682.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowesbair.org/img/5738682.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowesbair.org/img/5738682.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			quarkly-title="Form-1"
			background="--color-secondary"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%" color="--dark">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="580px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Adres
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
							Harbiye, Taşkışla Cd. No:2, <br/> 34367 Şişli/İstanbul, Türkiye
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:info@glowesbair.org" text-decoration="none" hover-text-decoration="underline" color="--dark">
									info@glowesbair.org
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Telefon
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+905322578495
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Box
							padding="56px 48px"
							margin="0 0 0 auto"
							md-max-width="100%"
							background="rgba(11, 11, 4, 0.52)"
							max-width="480px"
						>
							<Text as="h3" font="--headline3" margin="0 0 20px 0">
								Bize Ulaşın
							</Text>
							<Formspree endpoint="xeqpgrlv" completeText="Teşekkürler. Bir temsilcimiz en kısa sürede sizinle iletişime geçecek." errorMessage="Tüm alanların doldurulması gereklidir.">
								<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text margin="0 0 4px 0" font="--base">
												Ad
											</Text>
											<Input max-width="400px" width="100%" name="name" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Email
											</Text>
											<Input max-width="400px" width="100%" type="email" name="email" />
										</Box>
									</Box>
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Mesaj
											</Text>
											<Input width="100%" name="message" as="textarea" rows="4" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Components.QuarklycommunityKitCheckbox>
												<Override slot="Text" color="--light">
													{" "}Organizasyonun
													<Link href="/privacy-policy" text-decoration="none" hover-text-decoration="underline" color="--light">
														{" "}Gizlilik Politikası
													</Link>
													ile ilgili bilgi sahibiyim ve verilerimin işlenmesini kabul ediyorum.
												</Override>
												<Override
													slot="Input"
													min-width="20px"
													min-height="20px"
													text-align="left"
													display="block"
													margin="0px 5px 0px 0px"
												/>
											</Components.QuarklycommunityKitCheckbox>
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column" align-items="flex-start">
											<Button background="--color-secondary" text-decoration-line="initial" color="--dark">
												Gönder
											</Button>
										</Box>
									</Box>
								</Box>
							</Formspree>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});